export enum LanguageType {
    CZ = 43,
    SK = 198,
    US = 202,
    DE = 157,
    RO = 184,
    AL = 3,
    UK = 237,
    IT = 90,
    FR = 60,
    NL = 163,
}

/**
 * Helper function to get the enum key (e.g., 'CZ') by its value.
 * @param value - The numeric value of the LanguageType.
 * @returns The corresponding key as a string or 'Unknown' if not found.
 */
export function getLanguageKey(value: number): string {
    return (
        Object.keys(LanguageType).find(
            key => LanguageType[key as keyof typeof LanguageType] === value,
        ) || 'Unknown'
    )
}
