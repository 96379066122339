import { ReactElement } from 'react'
import { Title } from '../Title'
import { Translate } from '../../translations/CebiaTranslator'
import Moment from 'react-moment'
import { Table } from '../Table'
import { HistoryItem } from '../../models/history'
import { Button } from '../Button'
import { getLanguageKey, LanguageType } from '../../enums/LanguageType'

type Props = {
    history: HistoryItem[] | undefined
    activationDate: Date | undefined
    couponApc: string | undefined
    closeModal: () => void
}
export const CouponModalHistory = ({
    history,
    activationDate,
    couponApc,
    closeModal,
}: Props): ReactElement => {
    const tran = new Translate()
    return (
        <div className="Grid">
            <div className="Grid-cell md:w-12/12">
                <Title as="header" variantAlign="left">
                    <p>
                        <b>{tran.GetText('Historie prověřování VIN')} </b>
                    </p>
                    <p>
                        {tran.GetHtml(
                            'Přehled historie všech dotazů na prověřované VIN ke dni aktivace kuponu.',
                        )}
                    </p>
                    <Table>
                        <thead>
                            <tr>
                                <th className="text-left" colSpan={2}>
                                    {tran.GetText('Ke dni:')}{' '}
                                    <Moment format="DD.MM.YYYY - HH:mm">{activationDate}</Moment>
                                </th>
                                <th>{tran.GetText('Místo prověřování')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history?.length == 0 && (
                                <tr key={0}>
                                    <td colSpan={2}>{tran.GetText('Nebylo prověřováno')}</td>
                                </tr>
                            )}
                            {history?.map((historyItem, historyIndex) => (
                                <tr key={historyIndex}>
                                    <td>
                                        <Moment format="DD.MM.YYYY - HH:mm">
                                            {historyItem.datumAktivace}
                                        </Moment>
                                    </td>
                                    <td className="font-bold">
                                        {!historyItem.apc && tran.GetText('Zákazník')}
                                        {historyItem.apc === couponApc &&
                                            tran.GetText('Prověřeno Vámi')}
                                        {historyItem.apc &&
                                            historyItem.apc !== couponApc &&
                                            tran.GetText('Smluvní partner Cebia')}
                                    </td>
                                    <td className="font-bold">{historyItem.countryCode}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Title>
                <div className="lg:w-auto">
                    <Button
                        as="button"
                        variantColor="blue"
                        variantSize="small"
                        onClick={() => {
                            closeModal()
                        }}
                    >
                        {tran.GetText('Zavřít')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
